import React, { useEffect, useState } from 'react';
import iconLogo from "../../../assets/media/icon-logo.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveItem, setApplyingForm } from '../../../store/reducers/counterSlice';
import { fetchForms } from '../../../store/reducers/formSlice';
import classes from './style.module.css'
import FormContent from './FormContent';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5"
import { postApplication, safePdf } from '../../../store/reducers/applicationSlice';
import { uploadFile } from '../../../store/reducers/applicationSlice';
import { createNotification } from '../../../store/reducers/notificationSlice';
import { showSuccess } from '../../../helpers/toast';
import Form1 from '../forms/form_1'
import { loadForm } from '../../../utility/utility';
// import Form from './form';
import FormHeader from '../forms/formHeader/formHeader';
import AddAttachment from '../applications/attachments';

const AppForm = () => {

    const [formIds, setFormIds] = useState([]);
    const [inputFields, setInputFields] = useState({});
    const [pageIds,setPageIds] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const accessToken = useSelector((state) => state.auth.user.access_token);
    const categories = useSelector((state) => state.categories);
    const user = useSelector((state) => state.auth.user);
    const { applyingFormData } = useSelector((state) => state.counter)
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [signatureIds,setSignatureIds] = useState([]);
    const [imageData, setImageData] = useState('');
    const dispatch = useDispatch();
    const [showAttachments,setShowAttachments] = useState(false);
    const [files, setFiles] = useState({})
    const [fileNames, setFileNames] = useState({})

    useEffect(() => {
      if(applyingFormData){
        const elements = document.querySelectorAll('[id^="form"]');
        const ids = Array.from(elements)
          .filter(element => /^form\d+_\d+$/.test(element.id))
          .map(element => element.id);
        setFormIds(ids);
        setPageIds(getPageIds());
      }
      }, [applyingFormData]);
   
    
      useEffect(()=>{
        // console.log(pageIds);
        if(pageIds.length>0){
            pageIds.forEach(id => {
                if(id.replace(/^page/, '')!=currentPage){
                    const pageDiv = document.getElementById(id);
                    if (pageDiv) {
                      pageDiv.style.display = "none";
                    }
                }    
              });
        }
        console.log(pageIds.length);
      },[pageIds])

    useEffect(()=>{
      if(signatureIds.length>0){
        console.log("updating signature style");
        signatureIds.forEach(id => {
          const imageInput = document.getElementById(id);
          if (imageInput && imageInput.type === 'image') {
              // Directly set the src of the image input
              let originalWidth = window.getComputedStyle(imageInput).width.replace("px","")
              let originalTop = window.getComputedStyle(imageInput).top.replace("px","")
              let height = (originalWidth*114)/179
              imageInput.style.height =height+"px";
              imageInput.style.top = (originalTop-height+20)+"px";
              imageInput.style.objectFit = 'cover'; // Optional: Ensures the image covers the entire area
          }
      });
      }

    },[signatureIds])

    useEffect(()=>{
      if(files){
        setUploadedFiles((prevUploadedFiles) => [
          ...prevUploadedFiles,
          ...Object.values(files) // Extract values (files) from the `files` object
        ]);
      }
    },[files])

    // console.log({ location,navigate })




    // console.log({values:formik.values,errors:formik.errors,form})



   
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (formIds.length > 0) {
          attachEventHandlers();
        }
      }, [formIds.length]);
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputFields(prevInputFields => ({
          ...prevInputFields,
          [id]: value,
        }));
      
      };
    const attachEventHandlers = () => {
      let tempSignatureIds = []
      console.log(formIds);
        formIds.forEach(id => {
          const inputField = document.getElementById(id);
          if (inputField) {
            if(inputField.type === 'image'){
              tempSignatureIds.push(id);

            }else{
              inputField.addEventListener('change', handleInputChange);
            }
          }
       
        });
        setSignatureIds(tempSignatureIds);
      };
      async function handleSubmitWithLoading  (e)  {
        setIsLoading(true);
        console.log(inputFields);
        let htmlForm = document.getElementById("DocumentPDF").outerHTML; 
        htmlForm =  removeDisplayNoneByIds(htmlForm,pageIds);
        const data= await dispatch(postApplication({
            form:  applyingFormData?.name,
            json_data: inputFields,
            name: applyingFormData?.name,
            category: applyingFormData?.category,
            user_id: user.user.id,
            token: accessToken,
            files: uploadedFiles,
            state:"in_process"
            }))
            console.log(data);
        
          dispatch(safePdf({
          htmlForm,
          user_id: user.user.id,
          id: data.payload.id,
          token: accessToken
        }))
        setIsLoading(false);
    };

    async function saveToDrafts  (e)  {
      setIsLoading(true);
      console.log(inputFields);
      let htmlForm = document.getElementById("DocumentPDF").outerHTML; 
      htmlForm =  removeDisplayNoneByIds(htmlForm,pageIds);
      const data= await dispatch(postApplication({
          form:  applyingFormData?.name,
          json_data: inputFields,
          name: applyingFormData?.name,
          category: applyingFormData?.category,
          user_id: user.user.id,
          token: accessToken,
          files: uploadedFiles,
          state: e
          }))
          console.log(data);
      
        dispatch(safePdf({
        htmlForm,
        user_id: user.user.id,
        id: data.payload.id,
        token: accessToken
      }))
      setIsLoading(false);
      dispatch(createNotification({
        message:'Application Saved to drafts', 
        user_id:user.user.id, 
        accessToken :accessToken,
        is_admin:true
    }))
  };
    function removeDisplayNoneByIds  (htmlContent, ids) {
      // Parse the HTML string into a DOM structure
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
    
      // Loop through the array of IDs
      ids.forEach(id => {
        const element = doc.getElementById(id);
        if (element && element.style.display === 'none') {
          element.style.display = '';  // Remove the 'display: none' style
        }
      });
      // JSON.parse(formData);
      Object.entries(inputFields).forEach(([id, value]) => {
          // Get the input element by ID
          const element = doc.getElementById(id);
          
          // Check if the element exists and is an input field
          if (element && element.tagName.toLowerCase() === 'input') {
                  // Set the value of the input field
              element.setAttribute('value', value);

          }
      });
    //   signatureIds.forEach(id => {
    //     const imageInput = doc.getElementById(id);
    //     if (imageInput && imageInput.type === 'image') {
    //       console.log("imageData ",imageData);
    //       imageInput.setAttribute('src',imageData);
    //     }
    // });
      const modifiedHtmlContent = new XMLSerializer().serializeToString(doc);
      return modifiedHtmlContent;
    };
     const getPageIds = () => {
        const elements = document.querySelectorAll('[id^="page"]');
        const ids = Array.from(elements)
          .filter(element => /^page\d+$/.test(element.id))
          .map(element => element.id);
        return ids;
      };
    const previousPage = () =>{
      if(currentPage == pageIds.length+1){
        setShowAttachments(false);
        showDiv(currentPage-1);
        setCurrentPage(currentPage-1)
      }else{
        if(currentPage-1>=1){
          hideDiv(currentPage);
          showDiv(currentPage-1);
          setCurrentPage(currentPage-1);
        }  
      }
    }
  
    const nextPage = () =>{
      if(currentPage+1<=pageIds.length){
        hideDiv(currentPage);
        showDiv(currentPage+1);
        setCurrentPage(currentPage+1);
      }else if (currentPage+1<=pageIds.length+1){
        hideDiv(currentPage);
        setCurrentPage(currentPage+1);
        setShowAttachments(true);
      }
    }
    const hideDiv=(pageNo)=>{
      const pageDiv = document.getElementById("page"+pageNo);
      pageDiv.style.display = "none"; 
    }

    const showDiv=(pageNo)=>{
      const pageDiv = document.getElementById("page"+pageNo);
      pageDiv.style.display = ""; 
    }

  
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>                    
                <FormHeader applicationName={applyingFormData.name} nextPage={nextPage} previousPage={previousPage} 
                currentPage={currentPage} totalPages={pageIds.length+1} handleSubmitWithLoading={handleSubmitWithLoading}
                 setUploadedFiles={setUploadedFiles} signatureIds={signatureIds} setImageData={setImageData} 
                 saveToDrafts={saveToDrafts}/>

                    <div className={classes['board-content']}>
                          {loadForm({ type: applyingFormData.name })}
                    </div>
                   {showAttachments && <AddAttachment signatureIds={signatureIds} files={files} setFileNames={setFileNames}
                    setFiles={setFiles} fileNames={fileNames} />} 
                </div>
            </div>
        </div>
    )
}
export default AppForm
import { useState ,useRef} from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../navbar'
import Header from '../../header'
import SignatureCanvas from 'react-signature-canvas';
const AddAttachment = (props) => {
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);
    const [showSignaturePad, setShowSignaturePad] = useState(false); // State to show/hide signature pad
    const sigCanvas = useRef({});
    const clearCanvas = () => {
        sigCanvas.current.clear();
    };

    const saveSignature = () => {
        const trimmedDataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        // Convert DataURL to Blob (binary large object)
        fetch(trimmedDataUrl)
            .then(res => res.blob())
            .then(blob => {
                const signatureFile = new File([blob], 'formSignatureUser-esign.png', { type: 'image/png' });
                
                // Pass the file to the parent component or handle it here
                setSignatureImage(signatureFile);

                // Set the preview of the signature
                setTrimmedDataURL(trimmedDataUrl);
                setShowSignaturePad(false); // Hide the signature pad after saving
                props.setFiles((prevFiles) => {
                    return {
                        ...prevFiles,
                        ["signature"]: signatureFile
                    };
                });
                    // Update fileNames state with key-value pair
                props.setFileNames((prevFileNames) => {
                    return {
                        ...prevFileNames,
                        ["signature"]: signatureFile ? signatureFile.name : ''
                    };
                });
            });
    };

    const handleFileChange = (e, fileIndex) => {
        let selectedFile = e.target.files ? e.target.files[0] : null;
        console.log(fileIndex);
        console.log(props.fileNames);
        // Update files state with key-value pair
        if(fileIndex=="signature"){
            const newFileName = 'formSignatureUser.'+selectedFile.name.split('.').pop(); 
            const newFile = new File([selectedFile], newFileName, { type: selectedFile.type });
            selectedFile = newFile;
            setSignatureImage(selectedFile);
        }
        props.setFiles((prevFiles) => {
            return {
                ...prevFiles,
                [fileIndex]: selectedFile
            };
        });
            // Update fileNames state with key-value pair
        props.setFileNames((prevFileNames) => {
            return {
                ...prevFileNames,
                [fileIndex]: selectedFile ? selectedFile.name : ''
            };
        });
      
    };
    const setSignatureImage = (file)=>{
        const reader = new FileReader();
        reader.onload = (e) => {
            props.signatureIds.forEach(id => {
                const imageInput = document.getElementById(id);
                if (imageInput && imageInput.type === 'image') {
                    imageInput.src = e.target.result;
                }
            });
        };
        reader.readAsDataURL(file);
    }
    return (
        <div>
            <Navbar />
            <Header />
            <div>
                <div className='attachment'>
                    <h2>Required Attachments</h2>
                    <div className='fileUpload'>
                        <div className="form-group">
                            <input type="file" name="file" id="file-1" className="input-file" onChange={(e)=>handleFileChange(e,"1")} />
                            <label htmlFor="file-1" className="btn btn-tertiary">
                                {/* Conditionally apply the class based on whether a file is selected */}
                                <span className={props.files["1"] ? '' : 'js-fileName-upload'}>
                                    {props.fileNames["1"] || 'Choose a file'}
                                </span>
                            </label>
                        </div>
                        <div className="form-group">
                            <input type="file" name="file" id="file-2" className="input-file" onChange={(e)=>handleFileChange(e,"2")} />
                            <label htmlFor="file-2" className="btn btn-tertiary">
                                {/* Conditionally apply the class based on whether a file is selected */}
                                <span className={props.files["2"] ? '' : 'js-fileName-upload'}>
                                    {props.fileNames["2"] || 'Choose a file'}
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='attachment'>
                    <div className='fileUpload'>
                        <div className="form-group d-flex">
                            <div>
                            <input type="file" name="file" id="signature" className="input-file" onChange={(e)=>handleFileChange(e,"signature")} />
                            <label htmlFor="signature" className="btn btn-tertiary">
                                {/* Conditionally apply the class based on whether a file is selected */}
                                <span className={props.files["signature"] ? '' : 'js-fileName-upload'}>
                                    {props.fileNames["signature"] || 'Choose a file'}
                                </span>
                            </label>
                            </div>
                            <div>
                            <button
                                type='submit'
                                style={{
                                    backgroundColor: 'transparent',
                                    color: '#114df2',
                                    padding: '5px',
                                    float: 'right',
                                    border: '1px dotted #114df2',
                                    borderRadius: 5,
                                    width: '113px',
                                    height: '38.8px',
                                    fontSize: '16px',
                                    marginLeft: '10px',

                                }}
                                onClick={() => setShowSignaturePad(true)} // Show the signature pad on click

                            >e-Signature</button>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
            {showSignaturePad && (
    <div>
        <h2>Signature Pad</h2>
        <div style={{ border: '1px solid black', width: '400px', height: '200px' }}>
            <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}
            />
        </div>
        <div style={{ marginTop: '10px' }}>
            <button
                onClick={clearCanvas}
                style={{
                    backgroundColor: '#ff4d4d',
                    color: '#fff',
                    padding: '5px 10px',
                    border: 'none',
                    borderRadius: '5px',
                    marginRight: '10px',
                }}
            >
                Clear
            </button>
            <button
                onClick={saveSignature}
                style={{
                    backgroundColor: '#4caf50',
                    color: '#fff',
                    padding: '5px 10px',
                    border: 'none',
                    borderRadius: '5px',
                }}
            >
                Save
            </button>
        </div>
    </div>
)}
        </div>
    )
}

export default AddAttachment;

import React, { useEffect, useState } from 'react';
import iconLogo from "../../../assets/media/icon-logo.png";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveItem, setApplyingForm } from '../../../store/reducers/counterSlice';
import { fetchForms } from '../../../store/reducers/formSlice';
import { fetchApplication, postApplication } from '../../../store/reducers/applicationSlice';
import classes from './../appForm/style.module.css'
import FormContent from '../appForm/FormContent';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IoChevronBackSharp, IoChevronForwardSharp } from "react-icons/io5"
import { updateApplication } from '../../../store/reducers/applicationSlice';
import { uploadFile } from '../../../store/reducers/applicationSlice';
// import Form from './form';

const AppForm = () => {
    const { id } = useParams();
    const accessToken = useSelector((state) => state.auth.user.access_token);
    const categories = useSelector((state) => state.categories);
    const activeItem = useSelector((state) => state.counter.activeItem)
    const { applyingFormData } = useSelector((state) => state.counter)
    const user = useSelector((state) => state.auth.user);
    const forms = useSelector((state) => state.forms);
    const application = useSelector((state) => state.applications.application)

    const [form, setForm] = useState([])
    const [currentPage, setCurrentPage] = useState("")
    const [fileData, setFileData] = useState(null);
    
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const generate12DigitID = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let id = '';

        for (let i = 0; i < 12; i++) {
            if (i === 4 || i === 8) {
                id += '-';
            } else {
                const randomIndex = Math.floor(Math.random() * characters.length);
                id += characters.charAt(randomIndex);
            }
        }
        return id;
    }

    const [cards, setCards] = useState(JSON.parse(applyingFormData.form))
    
    useEffect(() => {
        setCurrentPage(form[0]?.id)
    }, [form])

    useEffect(() => {
        if(application?.json_data) setForm(JSON.parse(application?.json_data))
        return () => {
            setForm([])
        }
    }, [application])

    // useEffect(() => {
    //     setForm(JSON.parse(applyingFormData.form))
    //     return () => {
    //         setForm([])
    //     }
    // }, [applyingFormData])

    // console.log({accessToken})

    useEffect(() => {
        // dispatch(fetchForms({ accessToken: accessToken }))
        dispatch(fetchApplication({ accessToken: accessToken, id: id }))
    }, [accessToken, dispatch])
    
    const handleGoPrev = () => {
        const tempPages = [...form]
        const pageIndex = tempPages.findIndex((el) => el.id === currentPage)
        if (pageIndex === 0) return
        setCurrentPage(tempPages[pageIndex - 1].id)
    }

    const handleGoForw = () => {
        const tempPages = [...form]
        const pageIndex = tempPages.findIndex((el) => el.id === currentPage)
        if (pageIndex === tempPages.length - 1) return
        setCurrentPage(tempPages[pageIndex + 1].id)
    }

    const generateValidationSchema = (formPages) => {
        let validations = {};

        formPages.forEach((page, pageIndex) => {
            page.sections && page.sections.forEach((section, sectionIndex) => {
                section.column && section.column.forEach((columnn, columnnIndex) => {
                    columnn.row && columnn.row.forEach((roww, rowIndex) => {
                        roww.content && roww.content.forEach((contentItem, contentIndex) => {
                            const uniqueKey = `${pageIndex}_${sectionIndex}_${columnnIndex}_${rowIndex}_${contentIndex}`;
                            let currentValidation;

                            if (contentItem.type === 'Field') {
                                currentValidation = Yup.string();

                                if (contentItem?.validations?.validationType === 'Email') {
                                    currentValidation = currentValidation.email('Invalid email');
                                } else if (contentItem?.validations?.validationType === "Number") {
                                    currentValidation = Yup.number().typeError('Must be a number');
                                }

                                if (contentItem?.validations?.isRequired) {
                                    currentValidation = currentValidation.required('Required');
                                }
                            } else if (contentItem.type === 'Upload') {
                                currentValidation = Yup.mixed();
                                if (contentItem?.validations?.isRequired) {
                                    currentValidation = currentValidation.required('Required');
                                }
                            }

                            validations[uniqueKey] = currentValidation;
                        });
                    });
                });
            });
        });

        return Yup.object().shape(validations);
        // return validations
    }

    const handleTouchAllFields = () => {
        const touchedFields = Object.keys(formik.errors).reduce((acc, key) => {
            acc[key] = true;
            return acc;
        }, {});
    
        formik.setTouched(touchedFields);
    };

    // console.log({ location,navigate })

    const validationSchema = generateValidationSchema(form);

    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log({values,form})
            const currentPageIndex= form.findIndex((el) => el.id === currentPage);
            if(currentPageIndex < form.length -1){
                setCurrentPage(form[currentPageIndex+1].id)
            }
            else {
                console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk')
                dispatch(postApplication({
                    form:location?.state?.category,
                    json_data:JSON.stringify(form),
                    name:applyingFormData?.name,
                    category:applyingFormData?.category,
                    user_id:user.user.id,
                    token:accessToken
                }))
                // alert('last page')
                // return
            }
            // setCurrentPage()
            // On successful validation, send formData to the server.
            // This would be where you handle your form submission logic.
        },
    });

    // console.log({values:formik.values,errors:formik.errors,form})
    const handleInputChange = (pageIdx, sectionIdx, columnIdx, rowIdx, contentIdx, value) => {
        const newFormData = [...form];
        newFormData[pageIdx].sections[sectionIdx].column[columnIdx].row[rowIdx].content[contentIdx].value = value;
        setForm(newFormData);

        formik.handleChange({
            target: {
                name: `${pageIdx}_${sectionIdx}_${columnIdx}_${rowIdx}_${contentIdx}`,
                value: value,
            },
        });
    };
    const handleRadioChange = (pageIdx, sectionIdx, columnIdx, rowIdx, contentIdx, selectedItemIndex) => {
        const newFormData = [...form];
        const radioGroup = newFormData[pageIdx].sections[sectionIdx].column[columnIdx].row[rowIdx].content[contentIdx].radios;

        radioGroup.forEach((item, index) => {
            item.defaultChecked = index === selectedItemIndex;
        });

        setForm(newFormData);

        formik.handleChange({
            target: {
                name: `${pageIdx}_${sectionIdx}_${columnIdx}_${rowIdx}_${contentIdx}`,
                value: radioGroup[selectedItemIndex].value,
            },
        });
    };
    const handleCheckboxChange = (pageIdx, sectionIdx, columnIdx, rowIdx, contentIdx, itemIndex) => {
        const newFormData = [...form];
        const checkboxItem = newFormData[pageIdx].sections[sectionIdx].column[columnIdx].row[rowIdx].content[contentIdx].checkboxes[itemIndex];

        checkboxItem.defaultChecked = !checkboxItem.defaultChecked;

        setForm(newFormData);

        formik.handleChange({
            target: {
                name: `${pageIdx}_${sectionIdx}_${columnIdx}_${rowIdx}_${contentIdx}_${itemIndex}`,
                value: checkboxItem.defaultChecked,
            },
        });
    };
    const handleFileChange = async (pageIdx, sectionIdx, columnIdx, rowIdx, contentIdx, event) => {
        let url = ''
        const input = event.target;
        const fullPath = input.value;
        const formData = new FormData();
    
        if (input.files && input.files.length > 0) {
            let selectedFile = input.files[0];
            formData.append('file', selectedFile);
            const reader = new FileReader();
            const loadFile = () => new Promise((resolve) => {
                reader.onload = function (e) {
                    url = e.target.result;
                    resolve();
                };
            });
    
            await reader.readAsDataURL(selectedFile);
            await loadFile();
    
            const startIndex = fullPath.lastIndexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/');
            const filename = fullPath.substring(startIndex + 1);
            
            
            if (fullPath?.length > 0 && url?.length > 0) {
                const result = await dispatch(uploadFile({ payload: formData, accessToken: accessToken }));
                const fileData = {name: result?.payload?.data?.filename, url: process.env.REACT_APP_BACKEND_URL + result?.payload?.data?.path}

                // update json
                const newFormData = [...form];
                newFormData[pageIdx].sections[sectionIdx].column[columnIdx].row[rowIdx].content[contentIdx].value = fileData;
                setForm(newFormData);

                formik.handleChange({
                    target: {
                        name: `${pageIdx}_${sectionIdx}_${columnIdx}_${rowIdx}_${contentIdx}`,
                        value: fileData,
                    },
                });
            }
        }
    };
    
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>Form</h2>
                        </div>
                    </div>
                    <div className='AppFormBar noShadow'>
                        <div className='title'>
                            {applyingFormData?.category} / {applyingFormData?.name} / {form.find((el) =>el.id === currentPage)?.pageName}
                        </div>
                        <div className='pagination'>
                            <div className='pageNum d-flex align-items-center'>
                                <IoChevronBackSharp onClick={() => {
                                    const currentPageIndex = form.findIndex((el) => el.id === currentPage)
                                    if(currentPageIndex === 0) return
                                    setCurrentPage(form[currentPageIndex - 1].id)
                                }} />
                                <span className='pageCurrent d-flex align-items-center justify-content-center' style={{ width: 50, height: 50 }}>{(form.findIndex((el) => el.id === currentPage)+1)}</span> <span className='slash'>{`/`}<span style={{ marginLeft: 10 }}></span>{form.length}</span>
                                <IoChevronForwardSharp onClick={handleGoForw}/>
                            </div>
                        </div>
                    </div>
                    <div className={classes['board-content']}>
                        <form>
                            {
                                form?.map((page, pageIndex) => (
                                    <>
                                        {
                                            page?.id === currentPage && <div key={pageIndex} className={classes["page"]}>
                                                {
                                                    page?.sections?.map((section, sectionIndex) => (
                                                        <>
                                                            {
                                                                section.type === 'Divider' ? <div className='px-3'>
                                                                    <hr />
                                                                </div> : <div style={{ border: "solid 1px #ccc", padding: 15, margin: "40px 15px 15px 15px" }}>
                                                                    <p className={classes['section-heading']}>{section?.heading}</p>
                                                                    <div key={sectionIndex} style={{ display: "flex" }}>
                                                                        {
                                                                            section?.column?.map((col, columnIndex) => (
                                                                                <div key={columnIndex} style={{ flex: 1, marginRight: 10 }}>
                                                                                    {
                                                                                        col?.row?.map((roww, rowIndex) => (
                                                                                            <div className='mb-4' key={rowIndex} style={{ width: '100%', padding: '0 5px', marginBottom: 15 }}>
                                                                                                {
                                                                                                    roww?.content?.map((cont, contentIndex) => {
                                                                                                        // const { top, right, bottom, left } = cont.style.margin
                                                                                                        const uniqueKey = `${pageIndex}_${sectionIndex}_${columnIndex}_${rowIndex}_${contentIndex}`;
                                                                                                        return (
                                                                                                            <div key={contentIndex} style={{ width: "100%" }}>
                                                                                                                <FormContent
                                                                                                                    pageIndex={pageIndex}
                                                                                                                    sectionIndex={sectionIndex}
                                                                                                                    columnIndex={columnIndex}
                                                                                                                    rowIndex={rowIndex}
                                                                                                                    contentIndex={contentIndex}
                                                                                                                    element={cont}
                                                                                                                    handleInputChange={handleInputChange}
                                                                                                                    formik={formik}
                                                                                                                    handleRadioChange={handleRadioChange}
                                                                                                                    handleCheckboxChange={handleCheckboxChange}
                                                                                                                    handleFileChange={handleFileChange}
                                                                                                                    uniqueKey={uniqueKey}
                                                                                                                    setTemp={setForm}
                                                                                                                    currentPage={currentPage}
                                                                                                                    cardId={section?.id}
                                                                                                                    colId={col?.id}
                                                                                                                    rowId={roww?.id}
                                                                                                                />
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    ))
                                                }
                                            </div>

                                        }
                                    </>
                                ))
                            }
                            <div className='text-end mt-4'>
                                {
                                    (form[(form?.length - 1)]?.id === currentPage) ?
                                    <>
                                        
                                            <button type='button' style={{
                                                backgroundColor: 'rgb(17, 77, 242)',
                                                color: 'white',
                                                padding: '5px',
                                                float: 'right',
                                                borderRadius: '5px',
                                                width: '15%'
                                            }} onClick={(e) => {
                                                if(formik.isValid){
                                                    formik.handleSubmit()
                                                }
                                                else {
                                                    handleTouchAllFields()
                                                }
                                            }}>Update</button>
                                    </>
                                    :
                                    <>
                                        <button
                                            onClick={handleGoForw} 
                                            style={{backgroundColor:'#114df2',color:'white',padding:5,float:'right',border:'1px solid #114df2;',borderRadius:5,width:'15%'}} type='button'>
                                            Next
                                        </button>
                                    </>
                                }
                            </div>

                            {
                                (form[0]?.id !== currentPage) &&
                                <button 
                                    onClick={() => {
                                        const currentPageIndex = form.findIndex((el) => el.id === currentPage)
                                        if(currentPageIndex === 0) return
                                        setCurrentPage(form[currentPageIndex - 1].id)
                                    }}
                                    style={{backgroundColor:'#114df2',color:'white',padding:5,float:'right',border:'1px solid #114df2;',borderRadius:5,width:'15%'}} 
                                    type='button'
                                    className='me-2'
                                >
                                    Previous
                                </button>
                            }

                        </form>
                        {/* {
                            form?.map((page,pageIndex) => (
                                <>
                                {page?.id === currentPage && <Form page={page} currentPage={currentPage} pageIndex={pageIndex} classes={classes} />
}                                </>
                            ))
                        } */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppForm
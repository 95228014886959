import React ,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { loadForm } from '../../../utility/utility';
import classes from '../appForm/style.module.css'
import Navbar from '../../navbar';
import Header from '../../header';
import {fetchApplication,updateApplicationData,safePdf} from '../../../store/reducers/applicationSlice'
import {getSignature} from '../../../store/reducers/fileSlice';
import {setInputFieldAndFormPageIds,setFormFieldData,hideAllPagesExceptCurrentFormPage,getFormPageIds} from  '../../../utility/utility';
import FormHeader from '../forms/formHeader/formHeader';
import AddAttachment from './attachments';

const Draft = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;
    const params = useParams();
    const application = useSelector((state) => state.applications.application)
    const [pageIds,setPageIds] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const [formIds, setFormIds] = useState([]);
    const LoadedComponent = loadForm({ type: application.name });
    const [signatureImage,setSignatureImage] = useState('')
    const [inputFields, setInputFields] = useState({});
    const [signatureIds,setSignatureIds] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [imageData, setImageData] = useState('');
    const [showAttachments,setShowAttachments] = useState(false);
    const [files, setFiles] = useState({})
    const [fileNames, setFileNames] = useState({})
    useEffect(() => {
        if(LoadedComponent){
            setIsLoaded(true);    
        }
    }, [LoadedComponent]);

    useEffect(()=>{
        if(dispatch && params.id && authToken){
            dispatch(fetchApplication({id:params.id,accessToken: authToken }));
        }
    },[dispatch,params.id,authToken])


    useEffect(() => {
        const runAsyncFunctions = async () => {
            if (isLoaded) {
                await setInputFieldAndFormPageIds(setFormIds, setPageIds, getFormPageIds);
                await setFormFieldData(application.json_data);
                let signature =await getSignature({id:params.id,authToken});
                setSignatureImage(signature);
            }
        };
    
        runAsyncFunctions();
    }, [isLoaded]);


    useEffect(()=>{
        if(dispatch && params.id && authToken){
            dispatch(fetchApplication({id:params.id,accessToken: authToken }));
        }
    },[dispatch,params.id,authToken])
   
    useEffect(()=>{
        if(application?.json_data){
            setInputFields( JSON.parse(application.json_data));
        }
    },[application])
    useEffect(()=>{
        if(signatureImage!='' && signatureIds.length>0){
          signatureIds.forEach(id => {
            console.log("signatureImage = ",signatureImage);
            const imageInput = document.getElementById(id);
            if (imageInput && imageInput.type === 'image') {
                // Directly set the src of the image input
                let originalWidth = window.getComputedStyle(imageInput).width.replace("px","")
                let originalTop = window.getComputedStyle(imageInput).top.replace("px","")
                let height = (originalWidth*114)/179;
                imageInput.style.height =height+"px";
                imageInput.style.top = (originalTop-height)+20+"px";
                imageInput.src = signatureImage;
                setImageData(signatureImage);
            }
        });
        }
      },[signatureImage])
      
      useEffect(()=>{
          if(pageIds){
              hideAllPagesExceptCurrentFormPage(pageIds, currentPage);
          }
      },[pageIds])
  
      useEffect(() => {
          if (formIds.length > 0) {
            attachEventHandlers();
          }
        }, [formIds.length]);

        useEffect(()=>{
            if(files){
              setUploadedFiles((prevUploadedFiles) => [
                ...prevUploadedFiles,
                ...Object.values(files) // Extract values (files) from the `files` object
              ]);
            }
          },[files])

    const attachEventHandlers = () => {
        let tempSignatureIds = []
  
            formIds.forEach(id => {
              const inputField = document.getElementById(id);
              if(inputField.type === 'image'){
                tempSignatureIds.push(id);
  
              }else{
                inputField.addEventListener('change', handleInputChange);
              }
            });
            setSignatureIds(tempSignatureIds);
          };
      const handleInputChange = (event) => {
          const { id, value } = event.target;
  
          setInputFields(prevInputFields => ({
          ...prevInputFields,
          [id]: value,
          }));
        };
        function removeDisplayNoneByIds  (htmlContent, ids) {
            // Parse the HTML string into a DOM structure
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlContent, 'text/html');
          
            // Loop through the array of IDs
            ids.forEach(id => {
              const element = doc.getElementById(id);
              if (element && element.style.display === 'none') {
                element.style.display = '';  // Remove the 'display: none' style
              }
            });
            // JSON.parse(formData);
            Object.entries(inputFields).forEach(([id, value]) => {
                // Get the input element by ID
                const element = doc.getElementById(id);
                
                // Check if the element exists and is an input field
                if (element && element.tagName.toLowerCase() === 'input') {
                        // Set the value of the input field
                    element.setAttribute('value', value);
      
                }
            });
        //     console.log("signatureImage = ",signatureImage);
        //     signatureIds.forEach(id => {
        //       const imageInput = doc.getElementById(id);
        //       if (imageInput && imageInput.type === 'image') {
        //         // imageInput.setAttribute('src',signatureImage);
        //         // imageInput.src = signatureImage;
        //       }
        //   });
            const modifiedHtmlContent = new XMLSerializer().serializeToString(doc);
            return modifiedHtmlContent;
          };
    const previousPage = () =>{
            if(currentPage == pageIds.length+1){
              setShowAttachments(false);
              showDiv(currentPage-1);
              setCurrentPage(currentPage-1)
            }else{
              if(currentPage-1>=1){
                hideDiv(currentPage);
                showDiv(currentPage-1);
                setCurrentPage(currentPage-1);
              }  
            }
          }

    const nextPage = () =>{
            if(currentPage+1<=pageIds.length){
              hideDiv(currentPage);
              showDiv(currentPage+1);
              setCurrentPage(currentPage+1);
            }else if (currentPage+1<=pageIds.length+1){
              hideDiv(currentPage);
              setCurrentPage(currentPage+1);
              setShowAttachments(true);
            }
          }
    const hideDiv=(pageNo)=>{
        const pageDiv = document.getElementById("page"+pageNo);
        pageDiv.style.display = "none"; 
    }

    const showDiv=(pageNo)=>{
        const pageDiv = document.getElementById("page"+pageNo);
        pageDiv.style.display = ""; 
    }

    const handleSubmitWithLoading = (e) => {
 
        console.log(inputFields);
        let htmlForm = document.getElementById("DocumentPDF").outerHTML; 
        htmlForm =  removeDisplayNoneByIds(htmlForm,pageIds);
 
        dispatch( updateApplicationData({
            token:authToken,
            id: params.id,
            json_data : inputFields,
            htmlForm,
            state:'in_process'
        }))
        dispatch(safePdf({
            htmlForm,
            user_id: user.user.id,
            id: params.id,
            token: authToken
          }))
    };

    const saveToDrafts =(e) =>{
        console.log(inputFields);
        let htmlForm = document.getElementById("DocumentPDF").outerHTML; 
        htmlForm =  removeDisplayNoneByIds(htmlForm,pageIds);
 
        dispatch( updateApplicationData({
            token:authToken,
            id: params.id,
            json_data : inputFields,
            htmlForm,
            state:e
        }))

        dispatch(safePdf({
            htmlForm,
            user_id: user.user.id,
            id: params.id,
            token: authToken
          }))
    }
    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>                    
                <FormHeader applicationName={application.name} nextPage={nextPage} previousPage={previousPage} 
                currentPage={currentPage} totalPages={pageIds.length+1} handleSubmitWithLoading={handleSubmitWithLoading}
                setUploadedFiles={setUploadedFiles} signatureIds={signatureIds} setImageData={setImageData} 
                saveToDrafts={saveToDrafts}/>

                    <div className={classes['board-content']}>
                        {LoadedComponent}
                    </div>

                    {showAttachments && <AddAttachment signatureIds={signatureIds} files={files} setFileNames={setFileNames}
                    setFiles={setFiles} fileNames={fileNames}/>} 
                </div>
            </div>
        </div>
    )
}
export default Draft
import Form1 from "../components/pages/forms/form_1";
import Form3 from "../components/pages/forms/form_3";
import Form4 from"../components/pages/forms/form_4";
import Form5 from "../components/pages/forms/form_5";
import Form6 from "../components/pages/forms/form_6";
import Form7 from "../components/pages/forms/form_7";
import Form8 from "../components/pages/forms/form_8";
import Form9 from "../components/pages/forms/form_9";
import Form10 from "../components/pages/forms/form_10";
import Form11 from "../components/pages/forms/form_11";
import Form12 from "../components/pages/forms/form_12";
import Form13 from "../components/pages/forms/form_13";
import Form14 from "../components/pages/forms/form_14";
import Form15 from "../components/pages/forms/form_15";
import Form16 from "../components/pages/forms/form_16";
import Form17 from "../components/pages/forms/form_17";
import Form18 from "../components/pages/forms/form_18";
import Form19 from "../components/pages/forms/form_19";
import Form20 from "../components/pages/forms/form_20";
import Form21 from "../components/pages/forms/form_21";
import Form22 from "../components/pages/forms/form_22";
import Form23 from "../components/pages/forms/form_23";
import Form24 from "../components/pages/forms/form_24";
import Form25 from "../components/pages/forms/form_25";
import Form26 from "../components/pages/forms/form_26";
import Form27 from "../components/pages/forms/form_27";
import Form28 from "../components/pages/forms/form_28";
import Form29 from "../components/pages/forms/form_29";
import Form30 from "../components/pages/forms/form_30";

export const getFormPageIds = () => {
    const elements = document.querySelectorAll('[id^="page"]');
    const ids = Array.from(elements)
      .filter(element => /^page\d+$/.test(element.id))
      .map(element => element.id);
    return ids;
}


export const goToFormNextPage =(currentPage,pageIds,setCurrentPage) =>{
    if(currentPage+1<=pageIds.length){
        console.log("next page");
        hideDiv(currentPage);
        showDiv(currentPage+1);
        setCurrentPage(currentPage+1);
      }
}

export const goToFormPreviousPage = (currentPage,setCurrentPage) => {
    if(currentPage-1>=1){
        console.log(currentPage)
        hideDiv(currentPage);
        showDiv(currentPage-1);
        setCurrentPage(currentPage-1);
      }
}

export const hideAllPagesExceptCurrentFormPage = (pageIds,currentPage) =>{
    if(pageIds.length>0){
        pageIds.forEach(id => {
            if(id.replace(/^page/, '')!=currentPage){
                const pageDiv = document.getElementById(id);
                if (pageDiv) {
                  pageDiv.style.display = "none";
                  console.log("hiding page");
                }
            }    
          });
    }
}

export const setInputFieldAndFormPageIds = (setFormIds,setPageIds,getPageIds) =>{
    const elements = document.querySelectorAll('[id^="form"]');
    const ids = Array.from(elements)
      .filter(element => /^form\d+_\d+$/.test(element.id))
      .map(element => element.id);
    setFormIds(ids);
    setPageIds(getPageIds());
}

export const loadForm= ({type}) =>{
    const Component = formComponentMap[type];
    return Component ? <Component /> : null;
}

export const setFormFieldData = (formData) =>{
    const formDataMap = JSON.parse(formData);

    for (const [fieldId, value] of Object.entries(formDataMap)) {
        const element = document.getElementById(fieldId);
        if (element) {
            // Set the value of the input field
            element.value = value;
        } else {
            console.warn(`Element with id ${fieldId} not found.`);
        }
    }

}
export const hideDiv=(pageNo)=>{
    const pageDiv = document.getElementById("page"+pageNo);
    pageDiv.style.display = "none"; 
}

export const showDiv=(pageNo)=>{
    const pageDiv = document.getElementById("page"+pageNo);
    pageDiv.style.display = ""; 
}

const formComponentMap = {
    "Virtual reality gaming tax report": Form1,
    "Enhanced gaming permit renewal": Form3,
    "Interactive gaming tax statement": Form4,
    "Online gaming license application": Form5,
    "Progressive gaming license update": Form6,
    "Casual gaming tax submission":Form7,
    "Augmented reality gaming permit":Form8,
    "Remote gaming license extension":Form9,
    "Advanced gaming tax form":Form10,
    "Dynamic gaming license upgrade":Form11,
    "Massive multiplayer gaming permit":Form12,
    "Cloud-based gaming tax return":Form13,
    "Next-gen gaming license renewal":Form14,
    "Real-time gaming tax update":Form15,
    "Immersive gaming license application":Form16,
    "Futuristic gaming tax report":Form17,
    "Blockchain gaming license submission":Form18,
    "AI-powered gaming permit":Form19,
    "E-sports gaming tax statement":Form20,
    "Modern gaming license extension":Form21,
    "Inclusive gaming tax form":Form22,
    "Virtual economy gaming license":Form23,
    "Retro gaming tax return":Form24,
    "Cryptocurrency gaming permit":Form25,
    "High-definition gaming license":Form26,
    "Microtransaction gaming tax update":Form27,
    "Eco-friendly gaming license renewal":Form28,
    "Customizable gaming tax report":Form29,
    "3D-printed gaming permit":Form30,
    "Experiential gaming license application":Form1,
    "Holographic gaming tax statement":Form6,
    "Geolocation-based gaming license":Form22,
    "Community-driven gaming tax form":Form4,
    "Cybersecurity gaming permit":Form22,
    "AI-enhanced gaming license renewal":Form1,
    "Augmented world gaming tax update":Form6,
    "Multiplatform gaming license":Form22,
    "Social impact gaming tax report":Form4,
    "Quantum computing gaming permit":Form6,
    "Immersive universe gaming license":Form22,
    "In-game purchase gaming tax submission":Form4,
    "Virtual commerce gaming permit":Form5,
    "Collaborative gaming license application":Form1,
    "Blockchain-enabled gaming tax statement":Form6,
    "Next-level gaming permit":Form5,
    "Biofeedback gaming license":Form4,
    "Interactive narrative gaming tax form":Form1,
    "Self-learning gaming permit":Form21,
    "Futuristic gaming license renewal":Form20,
    "Environmentally conscious gaming tax update":Form1,
    "Gaming License & Tax":Form22

}